import PropTypes from "prop-types";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { Image } from "@/ui";
import Title from "@/ui/Title";
import Body from "@/ui/Body";

export default function Hero({ className = "", backgrounds, title, subtitle, color_variant }) {
  return (
    <div className={clsx(className, classes.hero)}>
      {
        backgrounds.map(bg => {
          if(bg.type == "image") {
            return <Image key={bg.id} img={bg.source} size="1400x468" alt={bg.alt_text} className={`${classes.bg_image}`} style={{opacity: bg.opacity ? bg.opacity : 1}} />;
          } else {
            return <video key={bg.id} autoPlay loop playsInline muted className={classes.bg_video} style={{opacity: bg.opacity ? bg.opacity : 1}}>
              <source src={bg.source_link || bg.source} type="video/mp4" />
            </video>
          }
        })
      }
      <div className={`overlay dark ${color_variant}`}></div>
      <div className={classes.content}>
        <Title title={title} tag="h1" className={classes.title} />
        <Body body={subtitle} className={classes.description} />
      </div>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
};
