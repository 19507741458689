import Hero from "@/components/Hero";
import SeoHead from "@/components/SeoHead";

export default function ErrorPage() {
  const page = {
    title: "404 - Page Not Found",
    subtitle: "",
    background: [],
    color_variant: "primary"
  }
  return (
    <div>
      <SeoHead
        page_name={`Page Not Found · Omega`}
        canonical_link={`https://www.omegaresource.co.uk/404/`}
      />
      <Hero backgrounds={page.background} title={page.title} subtitle={page.subtitle} color_variant={page.color_variant} />
    </div>
  );
}
